import './style.styl'
import { addSplideClasses, connectSplideArrows, connectSplideBullets, debounce, getSiblings, sel, selAll, vh, vw, mm, onDomReady, initObserver } from './utils'
import Checkout from './checkout'
import Product from './product'
import Shop from './shop'
import Thank from './thank'

const dataPage = sel('.page-wrapper')?.getAttribute('data-page')
switch (dataPage) {
  case 'home':
    Home()
    break
  case 'about':
    About()
    break
  case 'shop':
    Shop()
    break

  case 'product':
    Product()
    break
  case 'services':
    Services()
    break
  case 'checkout':
    Checkout()
    break
  case 'thank':
    Thank()
    break
  case 'blog':
    // Services()
    break

  case 'error':
    Error()
    break
  default:
    console.log('unknown data-page:', dataPage)
}
