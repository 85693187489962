import { initObserver, onDomReady, sel, selAll } from './utils'

export default function Checkout() {
  const proList$ = sel('.w-commerce-commercecheckoutorderitemslist')
  // console.log(proList$)
  initObserver(proList$, 100, '', (entries) => {
    const form = sel('.new-form')
    const weight$a = form.querySelectorAll('.checkout__weight')
    let weight = 0
    weight$a.forEach((el, i) => {
      const itemWeight = Number(el.textContent || el.innerText)
      weight += itemWeight
    })
    if (weight > 40000) {
      form.querySelector('.checkout__contact').style.display = 'block'
      form.querySelector('.w-commerce-commercecheckoutshippingmethodsemptystate').style.display = 'none'
    }
    console.log(weight)
  })
}
