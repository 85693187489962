import { onDomReady, sel, selAll, addSplideClasses, connectSplideArrows, getLineNum } from './utils'
import Splide from '@splidejs/splide'
import '@splidejs/splide/css'

export default function Product() {
  //   const sliderW$ = sel('.product-gallery__slider')
  ;(() => {
    const prefix = 'product-gallery'
    const el = '.' + prefix + '__slider'
    addSplideClasses(el)

    const slider = new Splide(el, {
      arrows: false,
      pagination: false,
      gap: '2rem',
      autoWidth: true,
      // perPage: 4,
      padding: { right: '5rem' },
      perMove: 4,
      speed: 1500,
      easing: 'cubic-bezier(0.16, 1, 0.3, 1)',
      drag: 'free',
      snap: false,
      breakpoints: {
        767: {
          // perPage: 3,
          perMove: 3,
          gap: '1rem',
        },
      },
    }).mount()
    connectSplideArrows(slider, prefix)
    // connectSplideCarouselBullets(slider, prefix)
  })()
  const pdfA = document.querySelector('.product__pdf-a')
  const _pdfLink = pdfA?.getAttribute('href')
  // const _pdfLinkFull = document.querySelector('.prodcut__pdf-a')?.href
  const pdfLink = _pdfLink !== '#' ? _pdfLink : 'about:blank'
  const _pdfFile = /[^/\\&\?]+\.\w{3,4}(?=([\?&].*$|$))/.exec(pdfLink)?.[0] // extract file name from url
  const pdfFile = _pdfFile?.split('_')[1]
  console.log('link', _pdfLink, pdfFile)
  const pdfEmbed = document.querySelector('.product__pdf-embed')
  pdfEmbed?.setAttribute('src', pdfLink)
  pdfA.textContent = pdfFile
  document.querySelector('.tab2').click()

  // ---------------
  setTimeout(() => {
    const conditionTopLines = getLineNum(sel('.condition-top'))
    if (conditionTopLines <= 2) sel('#condition-top-a').style.display = 'none'
    const qwe = sel('.condition__p-2')
    const conditionBottomLines = getLineNum(qwe)
    // console.log('conditionBottomLines', conditionBottomLines, qwe)
    if (conditionBottomLines <= 2) sel('#condition-bottom-a').style.display = 'none'
  }, 800)
}
