import { onDomReady, sel, selAll } from './utils'

export default function Checkout() {
  const params = new URL(document.location).searchParams
  // const cat = params.get('cat') || 'kitchen'
  const cat = params.get('cat') || 'all'
  // const cat = params.get('cat')
  // console.log(cat)

  // setTimeout(() => {
  // const catTabs$ = selAll('.cat__new-tab')
  const catsTab$ = sel(`.cat__new-tabs .cat__tab[data-cat=${cat}]`)
  const tabsTab$ = sel(`.cat__tabs .cat__tab[data-cat=${cat}]`)
  // const paneId = tabsTab$.getAttribute('aria-controls')
  // const pane$ = sel(`#${paneId}`)
  // const saleA$ = pane$.querySelector('.sidebar-link[data-cat=sale]')
  //
  // console.log(saleA$)
  // if (cat !== 'all') {
  //   const saleAUrl = new URL(saleA$?.href)
  //   saleAUrl.searchParams.set('cat', cat)
  //
  //   saleA$.href = saleAUrl.toString()
  // }

  tabsTab$?.click()
  catsTab$?.classList.add('w--current')
  // const tab$a = selAll('.cat__tab')
  // tab$a.forEach((tab$) => {
  //   const paneId = tab$.getAttribute('aria-controls')
  //   const pane$ = sel(`#${paneId}`)
  //   const paneFirstItem$ = pane$.querySelector('.sidebar-link')
  //   tab$.onclick = () => {
  //     paneFirstItem$?.click()
  //     // console.log('tab$', tab$, paneId, pane$, paneFirstItem$)
  //   }
  // })

  // sel(`.cat__tab[data-cat=${cat}]`).classList.add('is--active')
  // const tab$ = sel(`.empty-tab[data-cat=${cat}]`)
  // tab$?.click()
  // console.log(cat, tab$)
  // }, 30)
}
